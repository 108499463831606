import React, { useState, useEffect, useContext } from "react";
import AuthSidebar from "components/authentication";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileDropdown from "components/dropdown";
import SelectAddress from "components/map";
import { CartContext } from "context/CartContext";
import { useAddress } from "context/AddressContext";
import { IoIosArrowDown } from "react-icons/io";
import { AskLocation } from "components/map/AskLocation";
import { messages } from "helpers/variables";
import supportIc from "../../assets/icons/support_ic.png";
import storyIc from "../../assets/icons/story_ic.png";
import branchesIc from "../../assets/icons/branch-6.png";
import cartIc from "../../assets/icons/cart_ic.png";
import buyNowIcon from "../../assets/icons/buy_now_icon.png";
import HomeIcon from "../../assets/images/home.png";
import MenuIc from "../../assets/icons/menu.svg";
import { MenuContext } from "context/MenuContext";
import { MobileHeader } from "components/MobileHeader";

const Header = () => {
  const { menuRef } = useContext(MenuContext);

  const location = useLocation();
  const navigate = useNavigate();

  const isMobile = window.innerWidth <= 992;
  const { selectedAddress, updateSelectedAddress } = useAddress();
  const { cartItems } = useContext(CartContext);
  const [askLocationModal, setAskLocationModal] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(false)


  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // useEffect(() => {
  //   if (selectedAddress == null && window.location.pathname === "/") {
  //     setAskLocationModal(true);
  //   } else {
  //     setAskLocationModal(false);
  //   }
  // }, [selectedAddress]);

  const toggleLocationModal = () => {
    setIsLocationModalOpen(!isLocationModalOpen);
  };

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        return {
          status: true,
          coordinates: {
            lat: latitude,
            lng: longitude,
          },
        };
      } catch (error) {
        console.error("Error getting user location:", error);
        if (error.code === 1) {
          alert(messages.locationAlert);
        }
        return {
          status: false,
        };
      }
    } else {
      alert(messages.locationAlert);
      return {
        status: false,
      };
    }
  };

  const allowCurrentLocation = async () => {
    const isLocationEnabled = await getCurrentLocation();
    if (isLocationEnabled.status) {
      setAskLocationModal(false);
    } else {
    }
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerClassName = `main-bar-wraper navbar-expand-lg ${isSticky ? "is-fixed" : ""
    }`;

  const validateUser = () => {
    const token = localStorage.getItem("token");
    return (
      <>
        {token ? (
          <li className="extra-cell-container">
            <ProfileDropdown />
          </li>
        ) : (
          <li className="header_auth_btn" onClick={toggleSidebar}>
            <p className="home_icon_label_R">Login</p>
          </li>
        )}
      </>
    );
  };

  const isShowLocation = () => {
    if (
      location.pathname === "/checkout" ||
      location.pathname === "/profile" ||
      location.pathname === "/profile/order/track"
    ) {
      return false;
    } else return true;
  };

  const verifyToNavigateCart = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/checkout");
    } else {
      toggleSidebar();
    }
  };

  const ADDRESS_TYPES = {
    1: "Home",
    2: "Work",
    3: "Other",
  };

  const renderLocation = () => {
    return (
      <>
        {isShowLocation() ? (
          selectedAddress !== undefined && selectedAddress !== null ? (
            <div
              className="header_address_container"
              onClick={() => toggleLocationModal()}
            >
              <span className="selected_address_type">
                <span className="address_type_label">
                  {ADDRESS_TYPES[selectedAddress.type]}
                </span>
              </span>
              <div className="header_address_content">
                <span className="home_address_label">
                  {selectedAddress?.street?.length > 100
                    ? `${selectedAddress?.street?.substring(0, 100)}...`
                    : `${selectedAddress?.street}`}
                </span>
                <IoIosArrowDown className="home_icon" />
              </div>
            </div>
          ) : (
            <div
              className="header_address_container"
              onClick={() => toggleLocationModal()}
            >
              <span className="selected_address_type">
                <span className="address_type_label">Other</span>
              </span>
              <div className="header_address_content">
                <span className="home_address_label">Choose Location</span>
                <IoIosArrowDown className="home_icon" />
              </div>
            </div>
          )
        ) : (
          <div className="link-dark p-3 osahan-location text-decoration-none d-flex align-items-center gap-2  flex-shrink-0">
            <p className="home_icon_label1 other_header_title">
              Secure CheckOut
            </p>
          </div>
        )}
      </>
    );
  };

  const handleNavigateToMenu = () => {
    if (menuRef.current) {
      menuRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const mainClassname =
    isMobile &&
      (location.pathname === "/checkout" ||
        location.pathname === "/profile" ||
        location.pathname === "/categories" ||
        location.pathname === "/profile/orders" ||
        location.pathname === "/profile/address")
      ? "d-none"
      : "site-header";
  return (
    <>
      <header className={mainClassname}>
        <div className={headerClassName}>
          <div className={`main-bar`}>
            <div className="logo-header">
              <img
                src={require("assets/images/new_ninans_logo.webp")}
                alt="/"
                onClick={() => navigate("/")}
              />
              {/* {renderLocation()} */}
              <div 
                class="toggle-holder"
                onClick={() => setIsMobileHeaderOpen(!isMobileHeaderOpen)}
              >
                <div class={`toggle ${isMobileHeaderOpen ? 'toggle_on' : ''}`}>
                  <div class="menu-line"></div>
                </div>
              </div>
            </div>
            <div className="extra-nav">
              <div className="extra-cell">
                <ul>
                  <li>
                    <div
                      className="extra-cell-container"
                      onClick={() => navigate("/")}
                    >
                      <img src={HomeIcon} className="home_icon" alt="" />
                      <h5 className="home_icon_label1">Home</h5>
                    </div>
                  </li>
                  <li>
                    <div
                      className="extra-cell-container"
                      onClick={() => navigate("/our-story")}
                    >
                      <img src={storyIc} className="home_icon" alt="" />
                      <h5 className="home_icon_label1">Our Story</h5>
                    </div>
                  </li>
                  <li>
                    <div
                      className="extra-cell-container"
                      onClick={() => navigate("/menu")}
                    >
                      <img src={MenuIc} className="home_icon" alt="" />
                      <h5 className="home_icon_label1">Menu</h5>
                    </div>
                  </li>
                  <li>
                    <div
                      className="extra-cell-container"
                      onClick={() =>
                        navigate(`/categories?id=65f56543b309f7f0d7103dca`)
                      }
                    >
                      <button type="button" className="btn btn-square ">
                        <img src={buyNowIcon} className="home_icon" alt="" />
                      </button>
                      <h5 className="home_icon_label1">Order Now</h5>
                    </div>
                  </li>
                  <li>
                    <div
                      className="extra-cell-container"
                      onClick={() => navigate("/branches")}
                    >
                      <img src={branchesIc} className="home_icon" alt="" />
                      <h5 className="home_icon_label1">Branches</h5>
                    </div>
                  </li>
                  <li>
                    <div
                      className="extra-cell-container"
                      onClick={() => navigate("/support")}
                    >
                      <button type="button" className="btn btn-square ">
                        <img src={supportIc} className="home_icon" alt="" />
                      </button>
                      <h5 className="home_icon_label1">Support</h5>
                    </div>
                  </li>

                  {validateUser()}
                  {location.pathname !== "/checkout" && (
                    <li>
                      <div
                        className="extra-cell-container"
                        onClick={() => verifyToNavigateCart()}
                      >
                        <div className="btn btn-square">
                          <img src={cartIc} className="home_icon" alt="" />
                          {totalQuantity > 0 && (
                            <span className="badge">{totalQuantity}</span>
                          )}
                        </div>
                        <h5 className="home_icon_label1">Cart</h5>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileHeader 
        isMobileHeaderOpen = {isMobileHeaderOpen}
      />
      {/* AUTHENTICATION */}
      {isSidebarOpen && (
        <AuthSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      )}

      {/* CHOOSE ADDRESS FROM THE LIST */}
      {isLocationModalOpen && (
        <SelectAddress
          toggleLocationModal={toggleLocationModal}
          isOpen={isLocationModalOpen}
        />
      )}

      {/* ASK LOCATION AT THE BEGINNING */}
      {askLocationModal && (
        <AskLocation
          toggleLocationModal={toggleLocationModal}
          allowCurrentLocation={allowCurrentLocation}
        />
      )}

      {isSidebarOpen && (
        <div
          className={`offcanvas-backdrop  ${isSidebarOpen ? "show" : "fade"}`}
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default React.memo(Header);
