import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { IoMdClose } from "react-icons/io";
import { FaArrowLeftLong } from "react-icons/fa6";
import { IoHomeOutline, IoLocation } from "react-icons/io5";
import { MdWorkOutline, MdGpsFixed } from "react-icons/md";
import { createAddress } from '../../redux/reducer/home/homeAPI'
import { useAddress } from "context/AddressContext";
import { IoCloseCircle } from "react-icons/io5";

import { getCustomerAddressList } from "../../redux/reducer/home/homeAPI";
import MapComponent from './MapComponent';
import { messages } from 'helpers/variables';
import { getAuthToken } from 'service/utilities';
import { DefaultLoader } from 'components/loaders/defaultLoader';

const SelectAddress = ({ isOpen, toggleLocationModal }) => {
  const dispatch = useDispatch();
  const { updateSelectedAddress } = useAddress();

  const [userLocation, setUserLocation] = useState(null);
  const [selectedAddressType, setSelectedAddressType] = useState('HOME');
  const [isGoogleMapLoading, setMapLoading] = useState(null);

  const [showMapAndForm, setShowMapAndForm] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const [savedAddresses, setSavedAddresses] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [displayedAddresses, setDisplayedAddresses] = useState([]);
  const [showAllAddresses, setShowAllAddresses] = useState(false);

  const getAddressList = async () => {
    try {
      if(getAuthToken){
        setisLoading(true);
        const response = await dispatch(getCustomerAddressList());
        setSavedAddresses(response?.payload?.data);
        setShowLoadMore(response?.payload?.data?.length > 2);
        setDisplayedAddresses(response?.payload?.data?.slice(0, 2) || []);
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  useEffect(() => {
    const token = getAuthToken();
    if (token && isOpen) {
      getAddressList()
    }
  }, [isOpen]);


  const handleCancel = () => {
    setShowMapAndForm(false);
  };

  const handleAddressSelection = (selectedAddress) => {
    updateSelectedAddress(selectedAddress);
    toggleLocationModal();
  };

  const handleLoadMore = () => {
    setDisplayedAddresses(savedAddresses);
    setShowLoadMore(false);
    setShowAllAddresses(true);
  };

  const handleShowLess = () => {
    setDisplayedAddresses(savedAddresses.slice(0, 2));
    setShowAllAddresses(false);
    setShowLoadMore(true);
  };

  // const updateFetchedAddress = (address) => {
  //   validation.setFieldValue('street', address)
  // }

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async function (result) {
          if (result.state === "granted") {
            try {
              setMapLoading(true)
              setShowMapAndForm(true)
              const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
              });
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              setUserLocation({
                lat: latitude,
                lng: longitude
              });
            } catch (error) {
              if (error.code === 1) {
                alert(messages.locationAlert);
              }
            } finally {
              setMapLoading(false)
            }
          } else {
            // alert(messages.locationAlert);
            const position = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            getCurrentLocation()
            // return {
            //   status: true,
            //   coordinates: {
            //     lat: latitude,
            //     lng: longitude
            //   }
            // }
          }
        })
        .catch(err => {
          if (err.code === 1) {
            alert(messages.locationAlert);
          }
        })

    } else {
      alert(messages.locationAlert);

    }
  }

  return (
    <>
      {isOpen &&
        <div className={`modal modal-lg fade ${isOpen ? "show" : ""}`} id="add-delivery-location">
          <div className={`modal-dialog modal-dialog-centered ${showMapAndForm ? 'google_map_address' : ''}`}>
            <div className="modal-content">
              {showMapAndForm ?
                <>
                  <div className="modal-header  px-3 get_location_header">
                    <button type="button" className="delivery_location"  onClick={handleCancel} aria-label="Close">
                      <FaArrowLeftLong />
                      <span className="mx-2 setDev">Set delivery location</span>
                    </button>
                  </div>
                  <div className="modal-body p-0">
                    <div className="modal-scrollbar" >
                      {isGoogleMapLoading ?
                        <div className='py-5'>
                          <DefaultLoader />
                        </div>
                        :
                        <MapComponent
                          userLocation={userLocation}
                          toggleLocationModal={toggleLocationModal}
                        />
                      }
                    </div>
                  </div>
                </>
                :
                <div className='map_geolocation_wrapper'>
                  <div className="modal-header geo_location_header">
                    <h5 className="h6 modal-title map_model_title">Your Location</h5>
                    <button type="button" className="delivery_location" onClick={toggleLocationModal}>
                      <IoCloseCircle />
                    </button>
                  </div>
                  <div className="modal-body" >
                    <div className="modal-scrollbar" >
                      <div className="map_geolocation_inner_container" onClick={getCurrentLocation}>
                        <div className="map_location">
                          <MdGpsFixed />
                        </div>
                        <div className="map_geolocation_list">
                          <h4 className="map_geolocation_list_title">Current Location</h4>
                          <p className="map_geolocation_list_subtitle">Enable Your Current Location for better services</p>
                        </div>
                      </div>
                      {displayedAddresses.length > 0 ?
                        <div className="geo_location_map_list">
                          <div className="saved_address_list">
                            <div className="saved_address_title">Saved Address</div>
                          </div>
                          <div>
                            <>
                              {displayedAddresses.map((address) => (
                                <div key={address._id} className="saved_address_individual">
                                  <div onClick={() => handleAddressSelection(address)} className="saved_address_inner_wrapper">
                                    <div className="address_type">
                                      {address.type === 1 ? <IoHomeOutline /> : address.type === 2 ? <MdWorkOutline /> : <IoLocation />}
                                    </div>
                                    <div className="saved_address_content">
                                      <div className="saved_address_inner_title" onClick={() => handleAddressSelection(address)}>{address.type === 1 ? "Home" : address.type === 2 ? "Work" : "Other"}</div>
                                      <div className="saved_address_subtitle">{address.street ? `${address.street},` : ''}, {address.doorNo ? `${address.doorNo},` : ''} {address?.landmark}</div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              <div className="load_mre_btn_Wrapper">
                                {showLoadMore && !showAllAddresses && (
                                  <div className="load-more-button" onClick={handleLoadMore}>
                                    View More
                                  </div>
                                )}
                                {showAllAddresses && (
                                  <div className="show-less-button" onClick={handleShowLess}>
                                    View Less
                                  </div>
                                )}
                              </div>
                            </>
                          </div>
                        </div> : (
                          <></>
                        )}
                    </div>
                  </div>
                </div>
              }

            </div>
          </div>
        </div>
      }
      {isOpen && <div className={`offcanvas-backdrop  ${isOpen ? 'show' : 'fade'}`} onClick={toggleLocationModal}></div>}
    </>
  );


};

export default SelectAddress;
