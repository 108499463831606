import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header/header';
import { CartProvider } from 'context/CartContext';
import MobileMenu from 'components/mobileMenu';
import Footer from 'components/Footer';
import { AddressProvider } from 'context/AddressContext';
import { useLocation } from "react-router-dom";
import { AuthProvider } from 'context/AuthContext';
import MenuProvider from 'context/MenuContext';

export function MainLayout(props) {
  const { children } = props;
  const location = useLocation()

  const getFooter = () => {
    const pathname = location.pathname
    return (
      <>
        {
          pathname !== '/checkout' && 
          pathname !== '/profile' && 
          pathname !== '/categories' && 
          pathname !== '/profile/orders' ?
          <Footer />
          :
          <></>
        }
      </>
    )

  }
  return (
    <CartProvider>
      <AddressProvider>
        <AuthProvider>
          <MenuProvider>
            <Header />
            <div className='page_content'>
              {children}
            </div>
            {getFooter()}
            <MobileMenu />
            </MenuProvider>
        </AuthProvider>
      </AddressProvider>
    </CartProvider>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
