import React, { useEffect } from 'react'
import './style.scss';
import Loader from '../../assets/images/default_loader.svg'

export const DefaultLoader = () => {
    return (
        <div className='default_loader'>
            <img src={Loader} alt='loading'/>
        </div>
    );
}

export const AbsoluteScreenLoader = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);
    
    return (
        <>
            <div className='fixed_loader'></div>
            <img src={Loader} alt='loading' className='fixed_loader_img'/>
        </>
    )
}