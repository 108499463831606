import React, { createContext } from 'react';

export const MenuContext = createContext();

const MenuProvider = ({ children }) => {
    const menuRef = React.useRef(null);

    return (
        <MenuContext.Provider value={{ menuRef }}>
            {children}
        </MenuContext.Provider>
    );
};

export default MenuProvider;