import React from "react";
import CustomInput from "../FormInput/customInput";
import OtpInput from "react-otp-input";
import { FaArrowLeftLong } from "react-icons/fa6";
import LoadingButton from "components/buttons/LoadingButton";
import flag from "assets/icons/svg_icons/indiaflag.svg";

const LoginFormComponent = ({
  validation,
  toggleSidebar,
  handleSectionChange,
  isOpen,
  handleVerifyClick,
  showOTPInput,
  otp,
  handleOtpChange,
  handleEditClick,
  activeSection,
  otpError,
  isLoading,
  otpLoading,
  resetForm,
}) => {
  const handleResendClick = (e) => {
    e.preventDefault();
    validation.handleSubmit();
  };
  return (
    <>
      <div
        className={`offcanvas offcanvas-end d-lg-block d-none ${
          isOpen ? "show" : ""
        }`}
        tabIndex="-1"
        id="offcanvasLogin"
      >
        <div className="offcanvas-body">
          <button type="button" className={`btn-close`} onClick={resetForm}>
            <FaArrowLeftLong />
          </button>
          <div className="offcanvas-form">
            <div className="login-head">
              <div>
                <h4 className="login_title">
                  {showOTPInput ? "Verify" : "Login"}
                </h4>
                {showOTPInput && (
                  <div>
                    <p className="register-subtitle">
                      We've sent an OTP to your phone number
                    </p>
                  </div>
                )}
              </div>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mb-4">
                <div className="mobile_div">
                  <CustomInput
                    placeholder="XXX - XXX - XXXX"
                    name="mobileNumber"
                    type="text"
                    label="Mobile Number"
                    showLeftIcon={true}
                    leftIcon={
                      <>
                        <img src={flag} alt="India Flag" />
                        <p>+91</p>
                      </>
                    }
                    disabled={showOTPInput}
                    value={validation.values.mobileNumber || ""}
                    labelClassName="col-form-label"
                    validation={validation}
                    maxLength={10}
                  />
                  {showOTPInput && (
                    <i
                      className="fas fa-edit edit-icon"
                      onClick={handleEditClick}
                    ></i>
                  )}
                </div>
              </div>

              {!showOTPInput && (
                <div className="text-center">
                  <LoadingButton
                    name="submit"
                    type="submit"
                    className="btn btn-primary w-100 d-block btn-hover-2"
                    text="Login"
                    loading={isLoading}
                  />
                </div>
              )}
            </form>
            {showOTPInput && (
              <div className="otp-input-container">
                <label className="form-label">OTP</label>
                <div>
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span> - </span>}
                    renderInput={(props) => (
                      <input {...props} type="text" min="0" />
                    )}
                    containerStyle="otpInputContainer"
                    shouldAutoFocus
                    inputStyle={{
                      padding: "0px",
                    }}
                  />
                </div>
                {otpError && <p className="feedback">{otpError}</p>}
                <LoadingButton
                  name="submit"
                  type="submit"
                  className="btn btn-primary w-100 d-block btn-hover-2"
                  text="Verify OTP"
                  onClick={handleVerifyClick}
                  loading={otpLoading}
                />
                <div className="resend_container">
                  <button
                    className="btn btn-link resend_btn"
                    onClick={handleResendClick}
                  >
                    Resend OTP
                  </button>
                </div>
              </div>
            )}
            <p className="text-center m-t30 isNew">
              New to Ninan's Burger ?{" "}
              <span
                role="button"
                className="toggle-button"
                onClick={() => handleSectionChange("register")}
              >
                Register
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* mobile view */}
      <div
        className={`offcanvas offcanvas-end d-lg-none ${isOpen ? "show" : ""}`}
        tabIndex="-1"
        id="offcanvasLoginMobile"
      >
        <div className="offcanvas-body">
          <button
            type="button"
            className={`mobile-btn-close ${isOpen ? "show" : ""}`}
            onClick={toggleSidebar}
          >
            <FaArrowLeftLong />
          </button>

          <div className="offcanvas-form">
            <div className="login-head">
              <h4 className="login_title">
                {showOTPInput ? "Verify" : "Login"}
              </h4>
              <p className="register-subtitle">
                {showOTPInput ? "We've sent an OTP to your phone number." : ""}
              </p>
              {/* <img className={showOTPInput ? 'otp_image' : 'login_image'} width="100" height="105" imageid="" alt="img renderer" src={require('../../assets/images/login_image.jpg')}></img> */}
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <div className="mb-4">
                <div className="mobile_div">
                  <CustomInput
                    placeholder="XXX - XXX - XXXX"
                    name="mobileNumber"
                    type="text"
                    label="Mobile Number"
                    showLeftIcon={true}
                    leftIcon={
                      <>
                        <img src={flag} alt="India Flag" />
                        <p>+91</p>
                      </>
                    }
                    value={validation.values.mobileNumber || ""}
                    labelClassName="col-form-label"
                    validation={validation}
                    maxLength={10}
                  />
                  {showOTPInput && (
                    <i
                      className="fas fa-edit edit-icon"
                      onClick={handleEditClick}
                    ></i>
                  )}
                </div>
              </div>

              {!showOTPInput && (
                <div className="text-center">
                  <LoadingButton
                    name="submit"
                    type="submit"
                    className="btn btn-primary w-100 d-block btn-hover-2"
                    text="Login"
                    // onClick={handleClick}
                    loading={isLoading}
                  />
                  {/* <button name="submit" type="submit" className="btn btn-primary w-100 d-block btn-hover-2"><span>Login</span></button> */}
                </div>
              )}
            </form>
            {showOTPInput && (
              <div className="otp-input-container">
                <label className="otp-label">OTP</label>
                <div>
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={4}
                    renderSeparator={<span> - </span>}
                    renderInput={(props) => (
                      <input {...props} type="text" min="0" />
                    )}
                    containerStyle="otpInputContainer"
                    shouldAutoFocus
                    inputStyle={{
                      padding: "0px",
                    }}
                  />
                </div>
                {otpError && <p className="feedback">{otpError}</p>}
                <LoadingButton
                  name="submit"
                  type="submit"
                  className="btn btn-primary w-100 d-block btn-hover-2"
                  text="Verify OTP"
                  onClick={handleVerifyClick}
                  loading={otpLoading}
                />
                <div className="resend_container">
                  <button
                    onClick={handleEditClick}
                    className="btn btn-link resend_btn"
                  >
                    Resend OTP
                  </button>
                </div>
              </div>
            )}
            <p className="text-center m-t30 isNew">
              New to Ninan's Burger?{" "}
              <span
                role="button"
                className="toggle-button"
                onClick={() => handleSectionChange("register")}
              >
                Register
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginFormComponent;
